<template>
  <v-col>
    <!-- Vuetify Loading Component -->

    <!-- Content to display when not loading -->

    <PhoneSearchField v-if="isPhone || isTablet" />
    <Breadcrumbs :items="breadcrumbItems" />

    <v-col class="pa-0">
      <p class="bold-h1 mb-10">{{ searchTerm }}</p>
      <v-row v-if="loading" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>

      <AllProducts
        v-else
        :title="title"
        :products="products"
        :loadingMoreProducts="loadingMoreProducts"
        @loadMore="handleLoadMore"
      />
    </v-col>
  </v-col>
</template>

<script>
import AllProducts from "../AllProducts.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import { useI18n } from "vue-i18n";
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";
import Breadcrumbs from "../Breadcrumbs.vue";
/**
 * Component for displaying search results.
 * It fetches and displays products based on the search term from the route parameter.
 * It also redirects to the homepage if the storeId is undefined and reloads the API requests if the storeId changes.
 */
export default {
  name: "SearchResults",
  components: {
    AllProducts,
    Breadcrumbs,
  },
  data() {
    return {
      products: [],
      page: 1,
      isPhone: false,
      isTablet: false,
      loadingMoreProducts: false,
      loading: true,
    };
  },
  computed: {
    ...mapFields(["searchTerm"]),
    ...mapState(["currentLang"]),
    breadcrumbItems() {
      return [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        { text: `${this.t("search")}: ${this.searchTerm}`, disabled: true },
      ];
    },
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  watch: {
    async searchTerm(newVal, oldVal) {
      if (this.loading == false) {
        this.loading = true;
        this.products = [];
        await this.fetchProducts();
        this.loading = false;
      }
    },
    $route: async function (newRoute, oldRoute) {
      if (
        newRoute.query.q != undefined &&
        newRoute.query.q != this.searchTerm
      ) {
        this.searchTerm = newRoute.query.q;
      }
    },
    currentLang(newVal) {
      this.translateProducts();
    },
  },
  methods: {
    async translateProducts() {
      try {
        const limit = this.products.length;
        const response = await CategoryProductAPI.getProducts({
          searchString: this.searchTerm,
          limit: limit,
        });
        this.products = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          searchString: this.searchTerm,
          page: this.page,
        });
        this.products = [...this.products, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async handleLoadMore() {
      this.loadingMoreProducts = true;
      this.page += 1;
      await this.fetchProducts();
      this.loadingMoreProducts = false;
    },
  },
  async activated() {
    if (this.$route.query.q != undefined) {
      this.searchTerm = this.$route.query.q;
    }
    this.products = [];
    await this.fetchProducts();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>

<style scoped>
.breadcrumbs {
  max-width: 1300px;
  margin: auto;
  padding-left: 20px;
}
.grid-container {
  width: 100%;
  margin-bottom: 125px;
}
</style>
