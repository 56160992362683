<template>
  <v-app id="main">
    <LocationSelection />
    <vtoast ref="vtoast" />
    <HomePage />
  </v-app>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from "vue";
import { useHead } from "@unhead/vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { mapGetters } from "vuex";
import HomePage from "@/screens/HomePage.vue";
import vtoast from "@/components/vtoast.vue";
import LocationSelection from "@/components/locationSelection/LocationSelection.vue";
import store from "@/store";
import { useCookiebot } from "@ambitiondev/vue-cookiebot";

export default defineComponent({
  components: {
    HomePage,
    vtoast,
    LocationSelection,
  },

  setup() {
    // Define reactive state
    const i18n = useI18n();
    const store = useStore();
    // Watcher for store state changes
    watch(
      () => store.state.currentLang,
      (newVal) => {
        store.dispatch("setCurrentLang", newVal);
        i18n.locale.value = newVal;
      },
      { immediate: true }
    );
    useHead({
      title: "NearbyStore | Matbutiken online, med upphämtning nära dig",
      htmlAttrs: {
        lang: "sv",
      },
      meta: [
        { charset: "UTF-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1.0" },
        {
          hid: "description",
          name: "description",
          content:
            "NearbyStore är en matbutik på nätet där du hämtar maten direkt, nära dig. Med vårt nätverk av upphämtningsställen och små kompakta automatiserade lager får du som kund en mer flexibel onlinehandel och ett stort sortiment med låga priser dygnet runt.",
        },
        {
          name: "keywords",
          content:
            "nearby, store, matbutik, livsmedelbutik, linköping, mathem, mat, hem, ",
        },
        { name: "author", content: "Nearbystore Sverige AB" },
        {
          name: "apple-itunes-app",
          content: "app-id=1593224780, app-argument=https://nearbystore.se/",
        },
      ],
    });
    const { consentBanner } = useCookiebot();

    onMounted(() => {
      consentBanner();
    });
  },
});
</script>

<style>
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}
.custom-footer {
  z-index: 10;
}
::-webkit-scrollbar {
  background-color: #fff;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #ececec;
  border-left: 1px solid #ccc;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid transparent;
  border-right: 3px solid transparent;
  background-clip: padding-box;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
#main {
  touch-action: manipulation;
}

@media (max-width: 425px) {
  #main {
    overflow-x: hidden;
  }
}

/* Add the following style to set the background image on v-app */
</style>
