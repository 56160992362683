<template>
  <v-container fluid>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>

    <v-col align="start" justify="start" v-else>
      <Breadcrumbs :items="breadcrumbItems" />
      <v-row class="pa-4" align="center" justify="space-between">
        <p class="bold-h1">{{ recipe.name }}</p>
        <v-btn
          v-if="recipeIsUser"
          class="button text-none"
          color="success"
          elevation="0"
          :to="{
            name: 'createRecipe',
            params: {
              id: recipe._id,
            },
          }"
          >{{ $t("change_recipe") }}</v-btn
        ></v-row
      >

      <v-row justify="center">
        <v-col xl="10" align="start">
          <v-row class="mt-2" justify="center">
            <v-col
              cols="12"
              :md="mainContentWidth < 800 ? '12' : '6'"
              :lg="mainContentWidth < 800 ? '12' : '7'"
            >
              <v-col
                align="center"
                justify="center"
                class="pa-0"
                style="position: relative"
              >
                <div
                  style="
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 1000;
                  "
                >
                  <LikedButton :size="'large'" :recipe="recipe" />
                </div>
                <v-row
                  style="
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    z-index: 1000;
                  "
                  class="pa-4"
                  ><v-card
                    elevation="0"
                    class="pa-3 rounded-lg"
                    :color="getColor(recipe.difficulty)"
                    ><p class="pb-1 bold-h4">
                      {{ recipe.difficulty }}
                    </p></v-card
                  ><v-card class="ml-4 pa-3 rounded-lg" elevation="0"
                    ><p class="pb-1 bold-h4">
                      {{ recipe.time + " min" }}
                    </p></v-card
                  ></v-row
                >
                <v-img
                  v-if="recipe.mainImage || recipe.image"
                  :src="
                    recipe.mainImage ? recipe.mainImage.url : recipe.image.url
                  "
                  alt="Recipe Image"
                  :aspect-ratio="2 / 2"
                  cover
                  class="rounded-lg"
                ></v-img>
              </v-col>
              <p class="body-1 mt-4">{{ recipe.description }}</p>
              <v-card class="mt-4" elevation="0">
                <v-expansion-panels class="pt-0">
                  <v-expansion-panel elevation="0" class="pa-0">
                    <v-expansion-panel-title class="bold-h3">{{
                      $t("ingredients")
                    }}</v-expansion-panel-title>
                    <v-expansion-panel-text class="pa-0">
                      <div
                        v-for="(ingredients, index) in recipe.activeIngredients"
                        :key="ingredients.product._id"
                      >
                        <v-row class="pa-3"
                          ><p class="body-2">
                            {{
                              parseFloat(ingredients.weight) *
                                this.recipe.portions +
                              " " +
                              ingredients.weightUnit
                            }}
                          </p>
                          <p class="bold-h4 ml-2">
                            {{ ingredients.product.name }}
                          </p></v-row
                        >
                      </div></v-expansion-panel-text
                    ></v-expansion-panel
                  ></v-expansion-panels
                >
              </v-card>
              <v-card class="mt-4" elevation="0">
                <v-col>
                  <p class="bold-h2 pl-2">{{ $t("instructions") }}</p>

                  <div
                    v-for="(ingredients, index) in recipe.instructions"
                    :key="ingredients._id"
                  >
                    <v-row class="pa-3" align="center">
                      <v-col cols="1" align="center"
                        ><p class="bold-h4 ml-2">
                          {{ ingredients.step }}
                        </p></v-col
                      >
                      <v-col>
                        <p class="body-2 ml-2">
                          {{ ingredients.instruction }}
                        </p></v-col
                      >
                    </v-row>
                  </div></v-col
                >
              </v-card>
            </v-col>

            <v-col
              cols="12"
              :md="mainContentWidth < 800 ? '12' : '6'"
              :lg="mainContentWidth < 800 ? '12' : '5'"
            >
              <v-card elevation="0"
                ><v-col align="center"
                  ><p class="bold-h2">
                    {{ totalPrice.toFixed(2) + " kr" }}
                  </p>
                  <p class="body-2">
                    {{ portionPrice.toFixed(2) + " kr/portion" }}
                  </p>
                  <v-col>
                    <v-card color="secondary" elevation="0">
                      <v-row
                        class="pa-4"
                        justify="center"
                        align="center"
                        cols="12"
                      >
                        <v-col
                          class="align-stretch pa-0"
                          justify="center"
                          align="center"
                        >
                          <v-icon
                            size="large"
                            color="surface"
                            @click.stop="recipe.portions--"
                            >mdi-minus</v-icon
                          >
                        </v-col>
                        <v-col
                          class="align-stretch pa-0"
                          align="center"
                          justify="center"
                          sm="4"
                        >
                          <p
                            class="body-2"
                            style="color: #f5f5f9; text-overflow: ellipsis"
                          >
                            {{
                              $t("portions_count", {
                                count: recipe.portions,
                              })
                            }}
                          </p>
                        </v-col>
                        <v-col
                          class="align-stretch pa-0"
                          justify="center"
                          align="center"
                        >
                          <v-icon
                            size="large"
                            @click.stop="recipe.portions++"
                            color="surface"
                            >mdi-plus</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-btn
                      color="primary"
                      block
                      :variant="recipeInCart ? 'outlined' : 'elevated'"
                      class="xl-button text-none"
                      :loading="loadingRecipe"
                      :disabled="loadingRecipe"
                      @click="recipeInCart ? removeFromCart() : addToCart()"
                      >{{
                        recipeInCart
                          ? $t("remove_from_cart")
                          : $t("add_to_cart")
                      }}</v-btn
                    ></v-col
                  >
                </v-col></v-card
              >
              <v-card
                v-for="(ingredients, index) in recipe.activeIngredients"
                :key="ingredients.product._id"
                class="pa-2 mt-4"
                :color="ingredients.active ? 'surface' : 'grey-lighten-1'"
                elevation="0"
              >
                <v-row>
                  <v-col
                    align="center"
                    justify="center"
                    cols="3"
                    style="position: relative"
                  >
                    <DiscountPrice
                      v-if="ingredients.product.promotion"
                      style="
                        position: absolute;
                        top: 3px;
                        right: 0px;
                        z-index: 1000;
                      "
                      :size="{ width: 45, height: 45 }"
                      :promotion="ingredients.product.promotion"
                      :isWeight="ingredients.product.weightProduct"
                    />
                    <v-card
                      style="
                        position: absolute;
                        top: 50px;
                        left: 4px;
                        z-index: 1000;
                        height: 35px;
                        width: 35px;
                      "
                      @click="ingredients.active = !ingredients.active"
                      :color="ingredients.active ? 'primary' : 'white'"
                    >
                      <v-col class="pa-1" align="center" justify="center">
                        <v-icon v-if="ingredients.active" color="surface"
                          >mdi-check</v-icon
                        ></v-col
                      >
                    </v-card>

                    <v-img
                      :src="ingredients.product.thumbnail.url"
                      alt="Product Image"
                      class="hover"
                      height="100"
                      @click="handleProductClick(ingredients.product)"
                    />
                  </v-col>
                  <v-col align="space-between">
                    <v-row class="pa-2 pt-1">
                      <v-col class="pa-0">
                        <v-col sm="12" class="pa-0">
                          <p class="bold-1">
                            {{
                              Math.ceil(
                                this.recipe.portions *
                                  ingredients.portionQuantity
                              ) +
                              ". " +
                              ingredients.product.name
                            }}
                          </p></v-col
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pa-2 mt-0" align="center">
                      <v-card
                        v-if="ingredients.isBasic"
                        class="pa-1 mb-2 mr-2"
                        color="primary"
                        elevation="0"
                        ><p class="bold-2 pb-1">Basvara</p></v-card
                      >
                      <v-col class="pa-0">
                        <p class="body-4 pb-2">
                          {{
                            ingredients.product.manufacturer +
                            " " +
                            ingredients.product.displayWeight
                          }}
                        </p></v-col
                      ></v-row
                    >

                    <v-row class="pl-2 pr-3 mb-1 mt-0" align="start">
                      <v-col class="pa-0">
                        <p class="body-4">
                          {{
                            $t("comparison_price") +
                            " " +
                            ingredients.product.comparePrice +
                            " " +
                            ingredients.product.compareUnit
                          }}
                        </p>
                        <p class="bold-1">
                          {{
                            (ingredients.product.price != undefined
                              ? ingredients.product.weightProduct &&
                                ingredients.product.weightInKg != null
                                ? (
                                    ingredients.product.price *
                                    ingredients.product.weightInKg *
                                    Math.ceil(
                                      recipe.portions *
                                        ingredients.portionQuantity
                                    )
                                  ).toFixed(2)
                                : (
                                    ingredients.product.price *
                                    Math.ceil(
                                      recipe.portions *
                                        ingredients.portionQuantity
                                    )
                                  ).toFixed(2)
                              : "") + " kr"
                          }}
                        </p>
                      </v-col>
                      <v-col class="pa-0 pl-1" cols="6"
                        ><v-btn
                          color="primary"
                          class="button text-none"
                          variant="outlined"
                          @click="changeProduct(ingredients.product)"
                          ><v-icon class="mr-2 ml-2" size="18"
                            >mdi-cached</v-icon
                          >{{ $t("change") }}</v-btn
                        >
                        <SearchProductModal
                          :visible="changeProductModal"
                          :originalProduct="product"
                          @close="changeProductModal = false"
                          @changeProduct="productChanged"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row> </v-col
  ></v-container>
</template>
<script>
import Header from "@/components/Header.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatTime, getDayLabel } from "../utils.js";
import ProductButton from "../components/ProductButton.vue";
import RecipeAPI from "@/api/RecipeAPI";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import { useI18n } from "vue-i18n";
import LikedButton from "@/components/LikedButton.vue";
import SearchProductModal from "@/components/SearchProductModal.vue";
import { useHead, useSeoMeta } from "@unhead/vue";
export default {
  name: "ListDetail",
  components: {
    SearchProductModal,
    Header,
    LikedButton,
    ProductButton,
    Breadcrumbs,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      changeProductModal: false,
      loading: true,
      recipe: {},
      product: {},
      selectedProducts: [],
      loadingRecipe: false,
    };
  },

  computed: {
    ...mapState([
      "mainContentWidth",
      "cart",
      "localCart",
      "location",
      "isCartLoading",
      "user",
      "locationModalIsVisible",
    ]),
    recipeIsUser() {
      return this.user._id == this.recipe.userId;
    },
    recipeInCart() {
      return (
        this.cart.recipes.findIndex((e) => e.recipe == this.recipe._id) != -1
      );
    },
    breadcrumbItems() {
      // Initialize breadcrumbs with the home page
      let items = [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        ...(this.user != null && this.user._id == this.recipe.userId
          ? [
              {
                text: this.t("saved"),
                to: {
                  name: "saved",
                },
              },
            ]
          : [
              {
                text: this.t("recipe"),
                to: {
                  name: "recipe",
                },
              },
            ]),
        {
          text: this.recipe.name,
          disabled: true,
        },
      ];
      items.findIndex;
      return items;
    },
    totalPrice() {
      return this.recipe.activeIngredients
        .filter((e) => e.active == true)
        .reduce(
          (accumulator, currentValue) =>
            accumulator +
            (currentValue.product.weightProduct &&
            currentValue.product.weightInKg != null
              ? currentValue.product.price * currentValue.product.weightInKg
              : currentValue.product.price) *
              Math.ceil(this.recipe.portions * currentValue.portionQuantity),
          0
        );
    },
    portionPrice() {
      return this.recipe.activeIngredients
        .filter((e) => e.active == true)
        .reduce(
          (accumulator, currentValue) =>
            accumulator +
            (currentValue.product.weightProduct &&
            currentValue.product.weightInKg != null
              ? currentValue.product.price * currentValue.product.weightInKg
              : currentValue.product.price) *
              currentValue.portionQuantity,
          0
        );
    },
  },
  activated() {
    this.fetchRecipe();
  },
  async mounted() {
    this.fetchRecipe();
  },
  watch: {
    $route: async function (newRoute, oldRoute) {
      if (
        newRoute.name == "recipeDetail" &&
        newRoute.params.id != this.recipe._id
      ) {
        this.loading = true;

        await this.fetchRecipe();
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "addToCart",
      "removeFromCart",
      "clearCart",
      "checkoutCart",
      "addRecipeCart",
      "updateRecipeCart",
      "removeRecipeCart",
    ]),
    handleProductClick(product) {
      this.$router.push({
        name: "ProductDetail",
        params: { id: product._id },
      });
    },
    changeProduct(product) {
      this.product = product;
      this.changeProductModal = true;
    },
    getColor(name) {
      switch (name) {
        case "Lätt":
          return "success";
        case "Medel":
          return "warning";
        case "Svårt":
          return "error";
        default:
          return "success";
      }
    },
    async fetchRecipe() {
      try {
        this.loading = true;
        const response = await RecipeAPI.fetchRecipe(this.$route.params.id);
        this.recipe = response.data;
        this.recipe.activeIngredients = this.recipe.activeIngredients.map(
          (item) => {
            // Use Array.find to find the store object with storeID "1"
            return {
              ...item,
              active: !item.isBasic,
            };
          }
        );
        if (this.recipeInCart) {
          const recipe = this.cart.recipes.find(
            (e) => e.recipe == this.recipe._id
          );
          await recipe.products.forEach(async (element) => {
            const index = this.recipe.activeIngredients.findIndex(
              (e) =>
                e.product._id ==
                (element.originalProduct
                  ? element.originalProduct
                  : element.productId)
            );

            if (index != -1) {
              this.recipe.activeIngredients[index].active = true;
              this.recipe.activeIngredients[index].quantity = element.quantity;
              this.recipe.activeIngredients[index].portionQuantity =
                element.portionQuantity;
              if (element.originalProduct) {
                const resp = await CategoryProductAPI.getProduct(
                  element.productId
                );
                this.recipe.activeIngredients[index].product = resp.data;
              }
            }
          });
        }
        this.setHead();
        this.loading = false;
      } catch (error) {
        console.error("Error loading shopping lists:", error);
      } finally {
      }
    },
    async removeFromCart() {
      this.loadingRecipe = true;
      await this.removeRecipeCart({ recipeId: this.recipe._id });
      this.loadingRecipe = false;
    },
    async addToCart() {
      this.loadingRecipe = true;
      const products = [];
      this.recipe.activeIngredients.forEach((item) => {
        // Use Array.find to find the store object with storeID "1"

        if (item.active) {
          const product = {
            productId: item.product._id,
            quantity: Math.ceil(this.recipe.portions * item.portionQuantity),
            portionQuantity: item.portionQuantity,
          };
          if (item.replacedProduct) {
            product.replacedProduct = item.replacedProduct;
            product.originalProduct = item.originalProduct;
          }
          products.push(product);
        }
      });
      const recipe = {
        image: this.recipe.image,
        name: this.recipe.name,
        products: products,
        recipe: this.recipe._id,
        portions: this.recipe.portions,
      };
      await this.addRecipeCart({ recipe: recipe });
      this.loadingRecipe = false;
    },
    async productChanged(product) {
      const pIndex = this.recipe.activeIngredients.findIndex(
        (p) => p.product._id == product.id
      );
      if (pIndex != -1) {
        const originalProduct = this.recipe.activeIngredients[pIndex];
        const weightRatio =
          product.changedProduct.weightInKg /
          originalProduct.product.weightInKg;
        this.recipe.activeIngredients[pIndex].portionQuantity =
          originalProduct.portionQuantity / weightRatio;
        this.recipe.activeIngredients[pIndex].replacedProduct =
          originalProduct.product._id;
        this.recipe.activeIngredients[pIndex].originalProduct =
          originalProduct.originalProduct
            ? originalProduct.originalProduct
            : originalProduct.product._id;
        this.recipe.activeIngredients[pIndex].product = product.changedProduct;

        if (this.recipeInCart) {
          const recipe = {
            recipeId: this.recipe._id,
            productId: this.recipe.activeIngredients[pIndex].product._id,
            replacedId: this.recipe.activeIngredients[pIndex].replacedProduct,
            portionQuantity:
              this.recipe.activeIngredients[pIndex].portionQuantity,
            originalId: this.recipe.activeIngredients[pIndex].originalProduct,
            quantity: Math.ceil(
              this.recipe.portions *
                this.recipe.activeIngredients[pIndex].portionQuantity
            ),
          };
          await this.updateRecipeCart({
            recipe: recipe,
          });
        }
      }
    },
    setHead() {
      const recipeTitle = `${this.recipe.name} | Recept - NearbyStore`;
      useSeoMeta({
        title: recipeTitle,
        ogTitle: recipeTitle,
        ogImage: this.recipe.mainImage
          ? this.recipe.mainImage.url
          : this.recipe.image.url,
      });
      useHead({
        title: recipeTitle,
        meta: [
          {
            property: "og:url",
            content: window.location.href, // Automatically use current page URL
          },
          {
            property: "og:title",
            content: recipeTitle, // Ensure Open Graph title matches
          },
          {
            property: "og:image",
            content: this.recipe.mainImage
              ? this.recipe.mainImage.url
              : this.recipe.image.url,
          },
          {
            property: "og:image:secure",
            content: this.recipe.mainImage
              ? this.recipe.mainImage.url
              : this.recipe.image.url,
          },

          {
            hid: "description",
            name: "description",
            content:
              "Beställ idag på NearbyStore och få din mat levererad samma dag. Upptäck ett brett sortiment av färska råvaror och livsmedel, med bekväm hämtning nära dig. Shoppa enkelt och snabbt!",
          },
        ],
        __key: "recipeDetailHead",
      });
    },
  },
};
</script>
