import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseconfig";

function itemCreator(product, quantity = 1) {
  let price = product.price;

  if (product.deposit > 0) price = price + product.deposit;

  return {
    item_id: product._id,
    item_name: product.name,
    price: price,
    currency: "SEK",
    quantity: quantity,
  };
}

export function log_add_to_cart(product, quantity = 1) {
  // Log event when a product is added to the cart
  logEvent(analytics, "add_to_cart", [itemCreator(product, quantity)]);
}
export function log_remove_form_cart(product, quantity = 1) {
  // Log event when a product is added to the cart
  logEvent(analytics, "remove_from_cart", [itemCreator(product, quantity)]);
}

export function log_begin_checkout(cart) {
  const items = [];
  for (const p of cart.productVariants) {
    items.push(itemCreator(p.productVariant, p.quantity));
  }

  const params = {
    currency: "SEK",
    value: cart.price.total,
    items: items,
  };
  logEvent(analytics, "begin_from_cart", params);
}

export function log_purchase(cart) {
  const items = [];
  for (const p of cart.productVariants) {
    items.push(itemCreator(p.productVariant, p.quantity));
  }

  const params = {
    transactionId: cart._id,
    currency: "SEK",
    value: cart.price.total,
    shipping: cart.price.deliveryFee,
    tax: cart.price.tax,
    coupon: cart.appliedPromotion != null ? cart.appliedPromotion.code : null,
    items: items,
  };
  logEvent(analytics, "purchase", params);
}
