import { Directive, DirectiveBinding } from "vue";

export const sanitizeNumber: Directive = {
  mounted(el: HTMLDivElement, binding: DirectiveBinding) {
    // Find the actual input element inside the v-text-field
    const inputEl = el.querySelector("input") as HTMLInputElement;

    if (!inputEl) {
      console.error("Input element not found!");
      return;
    }

    // Store the current value to prevent unnecessary event dispatching
    let previousValue = inputEl.value;

    inputEl.addEventListener("input", () => {
      let value = inputEl.value;

      // Ensure `value` is a string and not undefined
      if (typeof value === "string") {
        // Remove commas and replace them with dots
        const sanitizedValue = value
          .replace(/,/g, ".")
          .replace(/[^\d.\-]/g, "");

        // Only update the input field if the value has actually changed
        if (sanitizedValue !== previousValue) {
          inputEl.value = sanitizedValue; // Update the input value
          previousValue = sanitizedValue; // Update the stored value

          // Manually trigger the input event after sanitization
          const event = new Event("input");
          inputEl.dispatchEvent(event); // This will trigger the @input handler
        }
      }
    });
  },
};
